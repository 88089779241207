import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button, Form, FormControl, FormLabel, Spinner, Image,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import './login.css';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    password: yup.string().label('Password').required().matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'The password you entered does not meet our password criteria. Please enter another.',
    ),
    password_confirmation: yup.string().label('Confirm Password').required(),
    token: yup.string().optional(),
  })
  .required();

const ResetPassword = () => {
  const title = 'Reset Password';
  const [isLoading, setIsLoading] = useState(false);
  const { sendResetPassword } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleResetPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await sendResetPassword(data);
      setIsLoading(false);
      navigate('/login', { state: { message: response } });
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      setValue('token', token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 p-3">
        <div className="loginCard">
          <Form className="row g-2" onSubmit={handleSubmit(handleResetPassword)} noValidate>
            <div className="col-md-12">
              <div className="text-center">
                <Link to={'/'}>
                  <Image src={logo} alt="logo" />
                </Link>
                <h3 className="mb-2">Set a new password</h3>
              </div>
              <Form.Group className="mb-3">
                <FormLabel className="mb-1">Password</FormLabel>
                <FormControl
                  type="password"
                  {...register('password')}
                  isInvalid={!!errors.password}
                  placeholder="Enter new password"
                />
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <FormLabel className="mb-1">Confirm Password</FormLabel>
                <FormControl
                  type="password"
                  {...register('password_confirmation')}
                  isInvalid={!!errors.password_confirmation}
                  placeholder="Enter confirmation password"
                />
                <Form.Control.Feedback type="invalid">{errors.password_confirmation?.message}</Form.Control.Feedback>
              </Form.Group>
              <FormControl type='text' {...register('token')} hidden />
              <Button className="w-100 btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={isLoading}
              >
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                <span className="px-2">Set New Password</span>
              </Button>
              <p className="mt-4 text-center">Back to <Link to={'/login'}>Login</Link></p>
            </div>
          </Form>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
