import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import './subscriptiondetail.css';
import visaIcon from '../../../assets/images/visaIcon.svg';

function SubscriptionDetail() {
  const title = 'Subscription Detail';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="subscription-detail">
        <div className="px-3 mx-5 pt-5">
          <div className="mb-4">
            <h2 className="h5 pb-3 mb-3 border-bottom">{title}</h2>
            <p className="text-muted mb-0">You can manage or change your subscription at any time. Read more about our 
              <Link to={'#'}>terms of service</Link> and  <Link to={'#'}>privacy policy</Link>.
            </p>
          </div>
          <Card className="subscriptionPlan">
            <Card.Header className="p-4 bg-transparent">
              <Row className="align-items-center vcmonthly-subscription">
                <Col md={9}>
                  <Card.Title>Basic Monthly</Card.Title>
                  <Card.Text className="text-muted">
                    Subscription is automatically renewed along with payment withdraw from registered credit card. 
                    You can cancel the subscription at any time.
                  </Card.Text>
                </Col>
                <Col md={3} className="text-end">
                  <span className="text-muted">USD</span>
                  <span className="fs-3 px-1">10</span>
                  <span className="text-muted">/ month</span>
                </Col>  
              </Row>
            </Card.Header>
            <Card.Body className="p-4">
              <Row>
                <Col md={6}>
                  <h6 className="text12 mb-0">PAYMENT METHOD</h6>
                </Col>
                <Col md={6} className="text-end">
                  <Link to={'#'} className="link">Change payment method</Link>
                </Col>
                <Col md={12} className="mt-2">
                  <div className="d-flex align-items-center">
                    <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />
                    <p className="mb-0 ms-3">amit pandsy<br />Ending in ************4242</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="p-4 bg-transparent text-end">
              <Button variant="outline-danger">Deactivate Store </Button>
              <Link to={'#'} className="ms-2 btn btn-primary">Change Plan</Link>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </>
  );
}

export default SubscriptionDetail;
