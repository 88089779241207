import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Button, Form, FormControl, FormLabel, Spinner, Image,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';

import './login.css';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    email: yup.string().email().label('Email').required(),
    password: yup.string().min(6).label('Password').required(),
  })
  .required();

const Login = () => {
  const title = 'Login';
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message || '';
  const handleLogin = async (data) => {
    // eslint-disable-next-line no-console
    // console.log(data.email, data.password);
    try {
      setIsLoading(true);
      const response = await login(data.email, data.password);
      toast.success(response);
      setIsLoading(false);
      navigate('/merchant');
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 p-3 bgLight">
        <div className="loginCard">
          <Form onSubmit={handleSubmit(handleLogin)} noValidate>
            <div className="text-center">
              <Link to={'/'}>
                <Image src={logo} alt="logo" />
              </Link>
              <h3>Login to your VCOM Account</h3>
              <span>{message}</span>
            </div>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Email</FormLabel>
              <FormControl
                type="email"
                className="form-control form-input-top"
                isInvalid={!!errors.email}
                {...register('email')}
                placeholder='Enter email'
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Password</FormLabel>
              <FormControl
                type="password"
                className="form-control form-input-bottom"
                isInvalid={!!errors.password}
                {...register('password')}
                placeholder="Enter Password"
              />
              <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button className="w-100 mt-1 btn btn-lg btn-primary"
                    type="submit"
                    disabled={isLoading}
            >
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">Login</span>
            </Button>
            <div className="row text-center mt-3">
              <div className="col-12">
                <Link to="/forgot">Forgot your password</Link>
              </div>
              <div className="col-12 mt-3">
                <p className="mb-0">Don't have an account?<Link to="/signup">Register here</Link></p>
              </div>
            </div>
          </Form>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Login;
