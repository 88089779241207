import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';

import './billing.css';
import visaIcon from '../../../assets/images/visaIcon.svg';

function Billing() {
  const title = 'Billing';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="planCreate">
        <div className="px-3 mx-5 pt-5">
          <h1 className="h5 pb-3 mb-3 border-bottom">{title}</h1>
          <h6 className="mb-1 text15">Payment methods</h6>
          <p className="text-muted mb-4">Manage how you pay your bills at VCOM. 
            <Link to={'#'}>Learn more about billing. <i class="bi bi-box-arrow-up-right ms-1"></i></Link>
          </p>
          <Card className="subscriptionPlan mb-4">
            <Card.Header className="p-4 bg-transparent">
              <Card.Title class="h6 mb-0">Default payment methods</Card.Title>
            </Card.Header>
            <Card.Body className="px-4 pt-3 pb-2">
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-top">
                    <div className="editPayment d-flex align-items-center">
                      <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />
                      <p className="mb-0 ms-3">amit pandsy<br />Ending in ************4242</p>
                    </div>
                    <Link to={'#'} className="delPayment"><i class="bi bi-x-circle-fill"></i></Link>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <hr />
            <Card.Body className="px-4 pt-1 pb-4">
              <div className="d-flex align-items-top">
                    <div className="editPayment d-flex align-items-center">
                      <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />
                      <p className="mb-0 ms-3">Amit Pandsy<br />Ending in ************4242</p>
                    </div>
                    <Link to={'#'} className="delPayment"><i class="bi bi-x-circle-fill"></i></Link>
                  </div>
            </Card.Body>
            <Card.Footer className="p-4 bg-transparent text-end">
              <Link to={'#'} className="edit">Add payment method</Link>
            </Card.Footer>
          </Card>
          <Card>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12}>
                  <Nav>
                    <Nav.Item>
                      <Nav.Link eventKey="all">All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="paid">Paid</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="failed">Failed</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="processing">Processing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="refunded">Refunded</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="all">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Invoice Date</th>
                            <th>Invoice Number</th>
                            <th>Plan</th>
                            <th>Amount</th>
                            <th>Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>11/10/2024</td>
                            <td>91A46879-0174</td>
                            <td>Basic Monthly</td>
                            <td>0 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>05/09/2024</td>
                            <td>91A46879-0173</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>29/08/2024</td>
                            <td>91A46879-0172</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>29/08/2024</td>
                            <td>91A46879-0171</td>
                            <td>Basic Monthly</td>
                            <td>0 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>09/12/2023</td>
                            <td>91A46879-0170</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>09/11/2023</td>
                            <td>91A46879-0167</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>02/11/2023</td>
                            <td>91A46879-0162</td>
                            <td>Basic Monthly</td>
                            <td>0 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>02/11/2023</td>
                            <td>91A46879-0164</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>09/10/2023</td>
                            <td>91A46879-0161</td>
                            <td>Basic Monthly</td>
                            <td>10 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>09/10/2023</td>
                            <td>91A46879-0159</td>
                            <td>Basic Monthly</td>
                            <td>0 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>07/10/2023</td>
                            <td></td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Processing</td>
                          </tr>
                          <tr>
                            <td>06/10/2023</td>
                            <td></td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Processing</td>
                          </tr>
                          <tr>
                            <td>05/10/2023</td>
                            <td></td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Processing</td>
                          </tr>
                          <tr>
                            <td>04/10/2023</td>
                            <td>91A46879-0158</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>03/10/2023</td>
                            <td>91A46879-0157</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>02/10/2023</td>
                            <td>91A46879-0156</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>01/10/2023</td>
                            <td>91A46879-0155</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>30/09/2023</td>
                            <td>91A46879-0154</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>29/09/2023</td>
                            <td>91A46879-0153</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>28/09/2023</td>
                            <td>91A46879-0151</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>27/09/2023</td>
                            <td>91A46879-0150</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>26/09/2023</td>
                            <td>91A46879-0149</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>26/09/2023</td>
                            <td>91A46879-0148</td>
                            <td>Basic Day</td>
                            <td>2 USD</td>
                            <td>Paid</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="paid">Second tab content</Tab.Pane>
                    <Tab.Pane eventKey="failed">three tab content</Tab.Pane>
                    <Tab.Pane eventKey="processing">for tab content</Tab.Pane>
                    <Tab.Pane eventKey="refunded">six tab content</Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </div>  
      </div>
    </>
  );
}

export default Billing;
