import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Image } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import './subscriptionplancreate.css';
import visaIcon from '../../../assets/images/visaIcon.svg';
import masterCard from '../../../assets/images/masterCard.svg';

function SubscriptionPlanCreate() {
  const title = 'Subscription Plan Create';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="planCreate">
        <div className="px-3 mx-5 pt-5">
          <h3 className="fs-5 mb-4">
            <Link to={'#'} className="backBtn"><i className="bi bi-arrow-left-short"></i></Link>
            Confirm payment for selected subscription plan
          </h3>
          <Row>
            <Col xs={8}>
              <Card className="p-4">
                <Card.Title className="fs-6 mb-3">Payment method</Card.Title>
                <Card.Text className="text-muted">
                  Select the credit card you want to use to pay for your subscription.
                </Card.Text>
                <div className="chooseCard">
                  <Form>
                    <Form.Check className="d-flex align-items-center mb-3">
                      <Form.Check.Input type='radio' name="payment_method" id="payment_method_0" />
                      <Form.Check.Label for="payment_method_0" className="text-muted ms-3">
                        <Image className="paymentIcon me-2" src={visaIcon} alt="visaIcon" />
                        amit pandey ending in ************4242
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check className="d-flex align-items-center">
                      <Form.Check.Input type='radio' name="payment_method" id="payment_method_1" />
                      <Form.Check.Label for="payment_method_1" className="text-muted ms-3">
                        <Image className="paymentIcon me-2" src={masterCard} alt="masterCard" />
                        amit pandey ending in ************4444
                      </Form.Check.Label>
                    </Form.Check>
                  </Form>
                  <div className="p-4 text-end">
                     <Link to={'#'}>Add payment method</Link>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={4}>
              <Card>
                <Card.Header className="border-0 bg-transparent pt-4 fs-6">Basic Monthly</Card.Header>
                <Card.Body>
                  <Card.Text className="text-muted">USD 10 + tax every 30 days</Card.Text>
                  <Card.Text>
                    As a result of this plan change, you now have a credit of USD 10 on your account. 
                    This will cover your subscription until 2024-11-10.
                  </Card.Text>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="border-top">
                    PLAN DETAILS
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div class="d-flex justify-content-between mb-2">
                      <span>Subtotal</span><span>USD 10</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <span>Plan change credit</span><span>-USD 10</span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <Card.Footer className="bg-transparent border-top py-3">
                  <div class="d-flex justify-content-between mb-3">
                    <span className="fw-500">Billed now</span>
                    <span className="fw-500">USD 10</span> 
                  </div>
                  <Button className="btn btn-primary w-100">Start plan</Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlanCreate;
