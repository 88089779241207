import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import './store.css';
import Storename from '../../../assets/images/storename.png';
import Industry from '../../../assets/images/industry.png';
import ecommerceAccount from '../../../assets/images/ecommerce-account.png';
import storeType from '../../../assets/images/storeType.png';
import backgroundImage from '../../../assets/images/backgroundImage.png';
import backgroundVideo from '../../../assets/images/backgroundVideo.png';
import storeDescription from '../../../assets/images/storeDescription.png';
import userIcon from '../../../assets/images/userIcon.png';
import phoneIcon from '../../../assets/images/phoneIcon.png';
import emailIcon from '../../../assets/images/emailIcon.png';
import billingIcon from '../../../assets/images/billingIcon.png';

function StoreDetail() {
  const title = 'Store Detail';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="store-page">
        <div className="px-3 mx-5 pt-5">
          <h2 className="title mb-3">Store Details</h2>
           <Card>
              <div className="cardTitle">
                <Row>
                  <Col><h3 className="fs-6 mb-0">Basic Information</h3></Col>
                  <Col className="text-end"><Link to={'/'} className="edit">Edit</Link></Col>
                </Row>
              </div>
              <div className="cardStore">
                <Row>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={Storename} alt="Storename" />
                      <div className="basicText">
                        <span className="text-muted text13">Store Name</span>
                        <h6>VCOMVERSE Dev</h6>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={Industry} alt="Storename" />
                      <div className="basicText">
                        <span className="text-muted text13">Industry</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col xs={6}>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={ecommerceAccount} alt="ecommerceAccount" />
                      <div className="basicText">
                        <span className="text-muted text13">Connected E-Commerce Account</span>
                        <h6>shop@vcomverse-dev.myshopify.com</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form>
                <div className="cardTitle">
                  <Row className="align-items-center">
                    <Col><h3 className="fs-6 mb-0">Basic Information</h3></Col>
                    <Col className="text-end storeBtn">
                      <Link to={'/'} className="cancel">Cancel</Link>
                      <Button className="ms-3" variant="primary" type="submit">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="cardStore">
                  <Row>
                    <Col xs={6}>
                      <div className="d-flex align-items-center gap12">
                        <Image src={Storename} alt="Storename" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Store Name</Form.Label>
                          <Form.Control type="text" name="store_name" class="form-control" value="VCOMVERSE Dev" />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex align-items-center gap12">
                        <Image src={Industry} alt="Industry" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Industry</Form.Label>
                          <Form.Control type="text" name="industry" class="form-control" />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex align-items-center gap12">
                        <Image src={ecommerceAccount} alt="ecommerceAccount" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Connected E-Commerce Account</Form.Label>
                          <Form.Control type="text" name="shopify_store_email" class="form-control" value="shop@vcomverse-dev.myshopify.com" />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
           </Card>
           <Card className="my-4">
              <div className="cardTitle">
                <Row>
                  <Col>
                    <h3 className="fs-6 mb-1">VCOM Market Settings</h3>
                    <p className="text-muted mb-0 text13">Change how your store get presented to customer on the VCOM Market.</p>
                  </Col>
                  <Col className="text-end"><Link to={'/'} className="edit">Edit</Link></Col>
                </Row>
              </div>
              <div className="cardStore">
                <Row>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={storeType} alt="storeType" />
                      <div className="basicText">
                        <span className="text-muted text13">Store Type</span>
                        <h6>Books & Magzines</h6>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={backgroundImage} alt="backgroundImage" />
                      <div className="basicText">
                        <span className="text-muted text13">Background Image</span>
                        <h6>1698652567678.jpeg</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={backgroundVideo} alt="backgroundVideo" />
                      <div className="basicText">
                        <span className="text-muted text13">Background Video</span>
                        <h6>1698652593077.mp4</h6>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={storeDescription} alt="storeDescription" />
                      <div className="basicText">
                        <span className="text-muted text13">Store Presentation</span>
                        <p className="mb-0">-</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form>
                <div className="cardTitle">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="fs-6 mb-1">VCOM Market Settings</h3>
                      <p className="text-muted mb-0 text13">Change how your store get presented to customer on the VCOM Market.</p>
                    </Col>
                    <Col className="text-end storeBtn">
                      <Link to={'/'} className="cancel">Cancel</Link>
                      <Button className="ms-3" variant="primary" type="submit">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="cardStore">
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={storeType} alt="Storename" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Store Type</Form.Label>
                              <Form.Select className="form-control" aria-label="Default select example">
                                <option>Choose one</option>
                                <option value="1">Art & Photography</option>
                                <option value="2">Automotive</option>
                                <option value="3">Books & Magzines</option>
                              </Form.Select>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={backgroundImage} alt="backgroundImage" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Background Image</Form.Label>
                          <Form.Control type="file" />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3 row">
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={backgroundVideo} alt="backgroundVideo" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Background Video</Form.Label>
                          <Form.Control type="text" name="shopify_store_email" class="form-control" value="shop@vcomverse-dev.myshopify.com" />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={storeDescription} alt="storeDescription" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Store Presentation</Form.Label>
                          <Form.Control as="textarea" placeholder="Leave a comment here" />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
           </Card>
           <Card>
              <div className="cardTitle">
                <Row>
                  <Col>
                    <h3 className="fs-6 mb-1">Contact Information</h3>
                    <p className="text-muted mb-0 text13">Used for VCOM billing and contacts about store and system information.</p>
                  </Col>
                  <Col className="text-end"><Link to={'/'} className="edit">Edit</Link></Col>
                </Row>
              </div>
              <div className="cardStore">
                <Row>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={userIcon} alt="userIcon" />
                      <div className="basicText">
                        <span className="text-muted text13">Name</span>
                        <p className="mb-0">-</p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={phoneIcon} alt="phoneIcon" />
                      <div className="basicText">
                        <span className="text-muted text13">Phone</span>
                        <p className="mb-0">-</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={emailIcon} alt="emailIcon" />
                      <div className="basicText">
                        <span className="text-muted text13">Email</span>
                        <h6>jesper.strandberg@website.se</h6>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="icon-with-text d-flex align-items-center gap12">
                      <Image src={billingIcon} alt="billingIcon" />
                      <div className="basicText">
                        <span className="text-muted text13">Billing Address</span>
                        <h6>Lansenvägen 60, test<br /> Täby<br />Sweden<br />187 62</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form>
                <div className="cardTitle">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="fs-6 mb-1">Contact Information</h3>
                      <p className="text-muted mb-0 text13">Used for VCOM billing and contacts about store and system information.</p>
                    </Col>
                    <Col className="text-end storeBtn">
                      <Link to={'/'} className="cancel">Cancel</Link>
                      <Button className="ms-3" variant="primary" type="submit">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="cardStore">
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={userIcon} alt="userIcon" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Name</Form.Label>
                            <InputGroup>
                              <Form.Control aria-label="First name" placeholder="First name" />
                              <Form.Control aria-label="Last name" placeholder="Last name" />
                            </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center gap12">
                        <Image src={phoneIcon} alt="phoneIcon" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Phone</Form.Label>
                          <Form.Control type="password" />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row class="pt-3">
                    <Col xs={6}>
                      <div className="d-flex align-items-center gap12">
                        <Image src={ecommerceAccount} alt="ecommerceAccount" />
                        <Form.Group className="mb-3 w-100">
                          <Form.Label class="mb-0 text-muted text13">Email</Form.Label>
                          <Form.Control type="text" name="email" class="form-control" value="jesper.strandberg@website.se" />
                          <Form.Text className="d-block" muted>VCOM uses this to contact you.</Form.Text>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row class="pt-3">
                    <Col xs={12}>
                      <div className="icon-with-text d-flex align-items-center gap12">
                        <Image src={billingIcon} alt="billingIcon" />
                        <div className="basicText">
                          <span className="text-muted text13">Billing Address</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mt-2 mb-3 w-100">
                        <Form.Label class="mb-0 text-muted text13">Street and house number</Form.Label>
                        <Form.Control type="text" name="address_street" class="form-control" value="Lansenvägen 60" />
                      </Form.Group>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label class="mb-0 text-muted text13">Apartment, Suite, etc.</Form.Label>
                        <Form.Control type="text" name="address_apartment" class="form-control" value="test" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label class="mb-0 text-muted text13">Postal Code</Form.Label>
                        <Form.Control type="text" name="address_postalcode" class="form-control" value="187 62" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label class="mb-0 text-muted text13">City/Town</Form.Label>
                        <Form.Control type="text" name="address_city" class="form-control" value="Täby" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label class="mb-0 text-muted text13">Country</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Choose one</option>
                              <option value="India">India</option>
                              <option value="Sweden">Sweden</option>
                              <option value="United States of America">United States of America</option>
                            </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Form>
           </Card>
           <Card className="my-3">
             <div class="cardTitle">
               <h3 class="fs-6 mb-1">Store currency</h3>
               <p class="text-muted mb-0 text13">The currency your products are sold in.</p>
             </div>
             <div class="cardStore">
                <Form.Group className="mb-3 w-100">
                  <Form.Label class="mb-0 text-muted text13">Store currency</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Choose one</option>
                        <option value="GBP">GBP</option>
                        <option value="EUR">EUR</option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </Form.Select>
                </Form.Group>
             </div>
           </Card>
           <Card>
             <div class="cardTitle">
               <h3 class="fs-6 mb-1">Standards and formats</h3>
               <p class="text-muted mb-0 text13">Used for calculate product price, shipping weighs, and order times.</p>
             </div>
             <div class="cardStore">
                <Row>
                  <Col xs={12}>
                    <Form.Group className="mb-3 w-100">
                      <Form.Label class="mb-0 text-muted text13">Store currency</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Time zone</option>
                        <option value="(GMT +01:00) Stockholm">(GMT +01:00) Stockholm</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3 w-100">
                      <Form.Label class="mb-0 text-muted text13">Unit system</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="Metric system">Metric system</option>
                        <option value="Imperial system">Imperial system</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3 w-100">
                      <Form.Label class="mb-0 text-muted text13">Default weight unit</Form.Label>
                      <Form.Control type="text" name="weight_unit" class="form-control" value="kg" />
                    </Form.Group>
                  </Col>
                </Row>
             </div>
           </Card>
        </div>
      </div>
    </>
  );
}

export default StoreDetail;
