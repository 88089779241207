import { Helmet } from 'react-helmet';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import './subscriptionplanlist.css';

function SubscriptionPlanList() {
  const title = 'Subscription Plan List';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="planList">
        <div className="px-3 mx-5 pt-5">
          <p className="fs-5 fw-500">Select a subscription plan for your VCOM account</p>
          <p className="text-muted">Choose a plan that suits you best. Subscription is automatically renewed along with payment withdraw 
          from registered credit card. You can cancel the subscription at any time.</p>
          <Row className="pt-3">
            <Col xs={4}>
              <Card className="p-4 text-center">
                <Card.Title className="mb-3">Basic Monthly</Card.Title>
                <Card.Text className="text-muted">This is monthly plan</Card.Text>
                <Card.Body className="pb-2">
                  <span className="text-muted">USD</span>
                  <span className="fs-3 px-1">10</span>
                  <span className="text-muted">/ month</span>
                </Card.Body>
                <Card.Footer className="p-0 bg-transparent border-0">  
                  <Link to={'/'} className="btn btn-primary">Choose Plan</Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="p-4 text-center">
                <Card.Title className="mb-3">Basic Yearly</Card.Title>
                <Card.Text className="text-muted">This is yearly plan</Card.Text>
                <Card.Body className="pb-2">
                  <span className="text-muted">USD</span>
                  <span className="fs-3 px-1">120</span>
                  <span className="text-muted">/ year</span>
                </Card.Body>
                <Card.Footer className="p-0 bg-transparent border-0">  
                  <Link to={'/'} className="btn btn-primary">Choose Plan</Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="p-4 text-center">
                <Card.Title className="mb-3">Basic Day</Card.Title>
                <Card.Text className="text-muted">This is day plan</Card.Text>
                <Card.Body className="pb-2">
                  <span className="text-muted">USD</span>
                  <span className="fs-3 px-1">2</span>
                  <span className="text-muted">/ day</span>
                </Card.Body>
                <Card.Footer className="p-0 bg-transparent border-0">  
                  <Link to={'/'} className="btn btn-primary">Choose Plan</Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="p-4 mt-4 text-center">
                <Card.Title className="mb-3">Advance Monthly</Card.Title>
                <Card.Text className="text-muted">Test</Card.Text>
                <Card.Body className="pb-2">
                  <span className="text-muted">USD</span>
                  <span className="fs-3 px-1">100</span>
                  <span className="text-muted">/ month</span>
                </Card.Body>
                <Card.Footer className="p-0 bg-transparent border-0">  
                  <Link to={'/'} className="btn btn-primary">Choose Plan</Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlanList;
