import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Form, FormControl, FormLabel, Spinner, Image,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import './login.css';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    email: yup.string().email().label('Email').required(),
  })
  .required();

const ForgotPassword = () => {
  const title = 'Forgot Password';
  const [isLoading, setIsLoading] = useState(false);
  const { sendForgotPassword } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await sendForgotPassword(data.email);
      setIsLoading(false);
      navigate('/login', { state: { message: response } });
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 p-3">
        <div className="loginCard">
          <Form className="row g-2" onSubmit={handleSubmit(handleForgotPassword)} noValidate>
            <div className="col-md-12">
              <div className="text-center">
                <Link to={'/'}>
                  <Image src={logo} alt="logo" />
                </Link>
                <h3 className="mb-2">Forgot your password?</h3>
                <p className="mb-4 text-start text-muted resetLink">
                    No problem! Just let us know your email address and we will email you
                    a password reset link that will allow you to choose a new one.
                </p>
              </div>
             <Form.Group>
                <FormLabel className="mb-1">Email</FormLabel>
                <FormControl
                  type="email"
                  {...register('email')}
                  isInvalid={!!errors.email}
                  placeholder="Enter email"
                />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </Form.Group>
              <Button className="w-100 btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={isLoading}
              >
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                <span className="px-2">Email Password Reset Link</span>
              </Button>
              <p className="mt-4 text-center">Back to <Link to={'/login'}>Login</Link></p>
            </div>
          </Form>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
