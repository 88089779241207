import { useNavigate, NavLink } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import useAuth from '../../hooks/useAuth';
import { logout } from '../../services/AuthService';

import './merchant-navbar.css';
import virturalStoreLogo from '../../assets/images/virturalStoreLogo.png';

function MerchantNavbar() {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.getSession();

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();
    navigate('/');
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  const getFirstLetter = (string) => string.charAt(0).toUpperCase();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top py-0 shadow">
      <div className="row align-items-center">
        <div className="col-md-2">
          <a className="navbar-brand d-flex p-0" href="/merchant">
            <Image src={virturalStoreLogo} alt="logo" />
          </a>
        </div>
        <div className="col-md-4 menu ps-0">
          <ul className="nav menu flex-nowrap">
            <li className="nav-item">
              <NavLink className="nav-link" end to={'/merchant'}>
                Commerce
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" end to={'#'}>
                Virtual Store
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-3">
            <h3 className="merchant-heading">Merchant Admin Portal</h3>
        </div>
        <div className="text-end togglerBtn">
          <button className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sidebarMenu"
                  aria-controls="sidebarMenu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"/>
          </button>
        </div>
        <div className="col-md-3">
          <div className="collapse navbar-collapse flex-grow-1 text-right" id="navbarDropdown">
            <ul className="navbar-nav ms-auto flex-nowrap">
              <li className="nav-item dropdown">
                <button className="nav-link dropdown-toggle nav-avatar-dropdown p-0"
                  id="dropdownMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="userIcon">{getFirstLetter(user.first_name)}{getFirstLetter(user.last_name)}</span>
                  {capitalizeFirstLetter(user.first_name)} {capitalizeFirstLetter(user.last_name)}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu">
                  <li>
                    <a className="dropdown-item" href='/merchant/profile'>Profile</a>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MerchantNavbar;
