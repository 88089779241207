import { Helmet } from 'react-helmet';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

// import useAuth from '../../hooks/useAuth';
import './dashboard.css';
// import useAuth from '../../hooks/useAuth';
import StoreIcon from '../../assets/images/merchant-card-icon/store.svg';
import SubscriptionIcon from '../../assets/images/merchant-card-icon/subscription.svg';
import ProductsIcon from '../../assets/images/merchant-card-icon/products.svg';

const Dashboard = () => {
  const title = 'Dashboard';
  // const { getSession } = useAuth();
  // const user = getSession();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="dashboard-page">
        <div className="px-3 mx-5 pt-5">
          <div className="row">
            <div className="col-md-5">
              <h3>Merchant Admin Portal</h3>
              <p className="fs-6">Manage VR-store, products, 3D content and much more.</p>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <div className="merchantCard border-l-yellow">
                    <div className="infoIcon">
                      <Image src={StoreIcon} alt="StoreIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="fs-6 mb-0">VR-store</h3>
                      <p className="mb-0 text12 text-muted pt-1">Preview</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="merchantCard border-l-green">
                    <div className="infoIcon">
                      <Image src={SubscriptionIcon} alt="SubscriptionIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="fs-6 mb-0">Subscription</h3>
                      <p className="mb-0 text12 text-muted pt-1">Basic Monthly</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="merchantCard border-l-blue ">
                    <div className="infoIcon">
                      <Image src={ProductsIcon} alt="ProductsIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="fs-6 mb-0">Synced products</h3>
                      <p className="mb-0 text12 text-muted pt-1">50 items</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="setupGuide">
              <h3 className="fs-6 mb-0">Setup guide</h3>
            </div>
            <div className="setupHolder">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={4} className="pe-0">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                          </svg>
                          Connect to online store
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                              <path d="M817.222,390.516C804.357,203.57,666.992,55.95,500,55.95c-142.84,0-265.43,105.907-305.07,260.421
                              C82.125,361.077,5.035,483.159,5.035,621.092c0,178.073,126.87,322.959,282.83,322.959h459.63
                              c136.447,0,247.47-126.821,247.47-282.583C994.965,534.323,921.799,425.243,817.222,390.516 M651.809,690.032l-70.721-80.748
                              c-10.112-11.578-13.144-28.914-7.692-44.04c5.451-15.009,18.394-24.871,32.659-24.871h30.366
                              C620.603,470.912,565.752,419.251,500,419.251c-19.717,0-38.799,4.51-56.632,13.468c-17.985,8.873-38.749-0.405-46.568-20.797
                              c-7.847-20.448,0.305-44.24,18.188-53.171c26.852-13.408,55.485-20.217,85.012-20.217c104.883,0,192.011,87.469,208.951,201.839
                              h38.544c14.291,0,27.207,9.861,32.66,24.869c5.501,15.127,2.444,32.464-7.645,44.04l-70.72,80.749
                              c-6.904,7.851-15.949,11.774-25.018,11.774C667.731,701.809,658.688,697.884,651.809,690.032 M227.513,552.212l70.695-80.745
                              c13.833-15.794,36.175-15.794,50.032,0l70.67,80.745c10.141,11.52,13.197,28.886,7.695,43.98
                              c-5.451,15.068-18.394,24.899-32.66,24.899h-30.366c15.82,69.492,70.72,121.12,136.421,121.12c19.667,0,38.696-4.508,56.53-13.406
                              c17.884-9.048,38.723,0.434,46.543,20.824c7.847,20.419-0.381,44.271-18.239,53.144C558.007,816.185,529.449,822.96,500,822.96
                              c-104.883,0-192.01-87.525-208.925-201.868h-38.57c-14.267,0-27.208-9.83-32.66-24.899
                              C214.368,581.098,217.399,563.731,227.513,552.212"></path>
                          </svg>
                          Import products
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="three">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                            <path d="M500.5,8.269C228.817,8.269,7.758,228.851,7.758,500.014c0,271.135,221.059,491.717,492.742,491.717
                            c271.684,0,492.742-220.582,492.742-491.717C993.242,228.851,772.184,8.269,500.5,8.269 M750.698,474.735L389.354,720.609
                            c-2.784,1.897-5.995,2.832-9.233,2.832c-2.642,0-5.257-0.595-7.672-1.869c-5.367-2.836-8.779-8.422-8.779-14.49V215.311
                            c0-6.066,3.41-11.624,8.779-14.487c5.399-2.835,11.905-2.467,16.905,0.992l361.375,245.815c4.433,3.089,7.159,8.14,7.159,13.581
                            C757.888,466.628,755.159,471.701,750.698,474.735"></path>
                          </svg>
                          Publish VR-store
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={8} className="ps-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="connectStore">
                          <h3 className="text17 mb-4">Connect VCOM account to online store</h3>
                          <div className="statusInfo">
                            <p className="mb-2"><b>You're connected</b></p>
                            <p className="mb-1"><b>Store:</b> VCOMVERSE Dev(vcomverse-dev.myshopify.com)</p>
                            <p className="mb-1"><b>StoreID:</b> #60466462777</p>
                          </div>
                          <div className="mt-4">
                            <Link to={'/'}><i class="bi bi-question-circle-fill me-1"></i>Learn more about how to connect to online store<i class="bi bi-box-arrow-up-right ms-1"></i></Link>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="storeProducts">
                          <h3 className="text17 mb-2">Import your store products</h3>
                          <p className="mb-5 text-muted">mport your products of choise from your connected online store. Click the "Get product list from online store" and select which products that should be available in your VR-store. After all selected products been imported, changes made in your online store product inventory will automatically be updated to you VCOM account.</p>
                          <Button variant="primary">Get product list from online store</Button>
                        </div>
                        <div className="mt-4">
                          <Link to={'/'}><i class="bi bi-question-circle-fill me-1"></i>Learn more about how to connect to online store<i class="bi bi-box-arrow-up-right ms-1"></i></Link>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="three">
                        <div className="vrStore">
                          <h3 className="text17 mb-2">Make your VR-store visible for customers</h3>
                          <p className="mb-5 text-muted">Publish your VR-store to make your products available at the VCOM Market. If you want to have a look at your store first, set your store in preview mode and sign in on the VCOM Market as store owner.</p>
                          <Form>
                            <div className="mb-3">
                              <Form.Check type='radio'>
                                <Form.Group className="mb-4">
                                  <div className="d-flex">
                                    <div>
                                      <Form.Check.Input type='radio' name="vr_store_preview" id="vr_store_preview" isValid />
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                        <circle cx="50" cy="50" r="49" fill="#478bc8"></circle>
                                        <circle cx="50" cy="50" r="43.5" fill="#ffffff"></circle>
                                        <circle cx="50" cy="50" r="21.375" fill="#000000" class="unchk"></circle>
                                        <circle cx="50" cy="50" r="14" fill="#17181a" class="unchk"></circle>
                                      </svg>
                                    </div>
                                    <div>
                                      <Form.Check.Label for="vr_store_preview" className="d-block text17">
                                        Preview Mode
                                      </Form.Check.Label>
                                      <Form.Text id="passwordHelpBlock" muted>
                                        VR-store will only be accessable by you and not visable for others.
                                        Recommended when verifying settings and store content before going live.
                                      </Form.Text>
                                    </div>
                                  </div>
                                </Form.Group>
                                <Form.Group className="mb-5">
                                  <div className="d-flex align-items-top imagecheckbox">
                                    <div>
                                      <Form.Check.Input type='radio' name="vr_store_published" id="vr_store_published" isValid />
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                        <circle cx="50" cy="50" r="49" fill="#478bc8"></circle>
                                        <circle cx="50" cy="50" r="43.5" fill="#ffffff"></circle>
                                        <circle cx="50" cy="50" r="21.375" fill="#000000" class="unchk"></circle>
                                        <circle cx="50" cy="50" r="14" fill="#17181a" class="unchk"></circle>
                                      </svg>
                                    </div>
                                    <div>
                                      <Form.Check.Label for="vr_store_published" className="d-block text17">
                                        Published
                                      </Form.Check.Label>
                                      <Form.Text id="passwordHelpBlock" muted>
                                      Go live and make your VR-store available for your customers.
                                      </Form.Text>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Form.Check>
                            </div>
                          </Form>
                          <div>
                            <Link to={'/'}><i class="bi bi-question-circle-fill me-1"></i>Learn more about how to connect to online store<i class="bi bi-box-arrow-up-right ms-1"></i></Link>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
